<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <h1>设备知识库</h1>
      </el-header>
      <el-main class="scrollable-main">
        <el-row :gutter="20" style="margin-bottom: 20px;">
          <el-col :span="12">
            <el-form>
              <el-form-item label="选择部门">
                <el-select v-model="selectedPart" clearable placeholder="请选择部门" @change="handlePartChange">
                  <el-option v-for="part in partments" :key="part.id" :label="part.name" :value="part.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form>
              <el-form-item label="选择车间">
                <el-select v-model="selectedLine" clearable placeholder="请选择车间" @change="handleLineChange">
                  <el-option v-for="line in lines" :key="line.id" :label="line.name" :value="line.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form>
              <el-form-item label="按设备名搜索">
                <el-input v-model="searchName" placeholder="请输入设备名" @input="handleSearch" />
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form>
              <el-form-item label="">
                <el-radio v-model="radio" label="1" @change="loadEquipment">仅显示报修过的设备</el-radio>
                <el-radio v-model="radio" label="2" @change="load">显示所有设备</el-radio>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-card v-for="item in filteredEquipments" :key="item.id" style="margin-bottom: 20px;">
          <div>{{ lines.find(v => v.id === item.lineId) ? lines.find(v => v.id === item.lineId).name : '' }} | {{ item.name }}
            <el-button style="float: right;" @click="goToDetail(item.id)">查看</el-button>
          </div>
        </el-card>
      </el-main>
      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "MEquipment",
  data() {
    return {
      reports: [],
      equipments: [],
      lines: [],
      users: [],
      partments: [],
      selectedPart: null,
      selectedLine: null,
      searchName: '',
      radio: '',
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    };
  },
  created() {
    this.load();
  },
  computed: {
    // 计算属性：根据选择的部门和车间筛选设备列表
    filteredEquipments() {
      return this.getFilteredEquipments();
    }
  },
  methods: {
    // 获取数据
    load() {
      this.request.get("/equipment").then(res => {
        this.equipments = res.data;
      });
      this.request.get("/line").then(res => {
        this.lines = res.data;
      });
      this.request.get("/partment").then(res => {
        this.partments = res.data;
      });
    },
    loadEquipment(){
      this.request.get("/equipment/findHistory").then(res => {
        this.equipments = res.data;
      });
    },
    goToDetail(equipmentId) {
      console.log('Clicked item id:', equipmentId);
      this.$router.push({ name: 'MEquipmentDetail', params: { id: equipmentId } });
    },
    // 根据部门和车间筛选设备
    // getFilteredEquipments() {
    //   if (this.selectedPart && this.selectedLine) {
    //     return this.equipments.filter(item => item.partId === this.selectedPart && item.lineId === this.selectedLine);
    //   } else if (this.selectedPart) {
    //     return this.equipments.filter(item => item.partId === this.selectedPart);
    //   } else if (this.selectedLine) {
    //     return this.equipments.filter(item => item.lineId === this.selectedLine);
    //   } else {
    //     return this.equipments;
    //   }
    // },
    getFilteredEquipments() {
      let filtered = this.equipments;
      if (this.selectedPart) {
        filtered = filtered.filter(item => item.partId === this.selectedPart);
      }
      if (this.selectedLine) {
        filtered = filtered.filter(item => item.lineId === this.selectedLine);
      }
      if (this.searchName) {
        filtered = filtered.filter(item => item.name.includes(this.searchName));
      }
      return filtered;
    },
    // 处理部门选择变化
    handlePartChange() {
      this.filteredEquipments = this.getFilteredEquipments();
    },
    // 处理车间选择变化
    handleLineChange() {
      this.filteredEquipments = this.getFilteredEquipments();
    },
    // 处理设备名搜索
    handleSearch() {
      this.filteredEquipments = this.getFilteredEquipments();
    },
    goToHome() {
      this.$router.push('/mobile');
    },
  },
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.el-header {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.el-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.scrollable-main {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}
</style>
